import React, { useState, useEffect } from 'react';
const apiBasePath = process.env.REACT_APP_PATIENT_API_BASE_PATH;

const MagicLinkRedirect = () => {
    const [error, setError] = useState(null);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const token = params.get('token');
        const group = params.get('group');
        console.log('group:', group);
        console.log('token:', token);

        if (token) {
            handleMagicLinkRedirect(token, group);
        } else {
            setError('Invalid magic link.');
        }
    }, []);

    const handleMagicLinkRedirect = async (token, group) => {
        try {
            // Decode the token (first URL-decode, then base64-decode)
            const decodedToken = token;
            console.log('decodedToken:', decodedToken);

            //  Make the API call to validate the magic link
            const response = await fetch(`${apiBasePath}/verifymagiclink`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    token: decodedToken,
                }),
            });
            console.log('response:', response);

            const data = await response.json();

            console.log('data:', data?.tokens.idToken);

            if (response.ok && data?.tokens?.idToken) {
                console.log(`POST request successful: ${JSON.stringify(data)}`);

                const groupSessionKey = sessionStorage.key('group');
                sessionStorage.removeItem(groupSessionKey);

                let getUserGroup = getProgramGroupURL(data?.tokens?.idToken);

                console.log('getUserGroup:', getUserGroup);

                if (getUserGroup.program_group.toLowerCase() === group.toLowerCase()) {
                    window.location.href = `https://${getUserGroup['program_group_url']}/?waa=${btoa(data?.tokens?.idToken)}`;
                } else {
                    setError('Invalid group. Please check your program group.');
                }
            } else {
                console.error('Error occurred: ', JSON.stringify(data));
                setError('Invalid magic link. Please try again.');
            }
        } catch (err) {
            console.error('Error:', err);
            setError('An error occurred. Please try again later.');
        }
    };

    function getProgramGroupURL(token) {
        let progGroupURL = "https://opal.wellnesstoolbox.ca/?waa=";
        try {
            if (token) {
                const tokenArray = token.split(".");
                if (tokenArray?.length === 3) {
                    const claims = JSON.parse(atob(tokenArray[1]));
                    console.log(
                        `claims : ${claims} program Group URL : ${claims["custom:program_group_url"]}`
                    );
                    return {
                        program_group: claims["custom:program_group"],
                        program_group_url: claims["custom:program_group_url"],
                    };
                }
            }
        } catch (Error) {
            console.error(Error);
            return { program_group: "", program_group_url: progGroupURL };
        }
    }

    return (
        <div className="magic-link-redirect">
            {error ? (
                <div className="error-message">{error}</div>
            ) : (
                <div>Authenticating, please wait...</div>
            )}
        </div>
    );
};

export default MagicLinkRedirect;
